import React from "react";
import "./Form.scss";
const Form = () => {
  return (
    <>
      <div className="contactMain">
        <div className="form col-5">
          <div>
            <h1>Need help?</h1>
            <p>Reach out to the world’s most reliable IT services.</p>
          </div>
          <form action="#" method="#" className="contactForm">
            <div className="form-NE">
              <input
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
                type="text"
              />
              <input
                className="form-control"
                id="email"
                placeholder="Email*"
                type="email"
              />
            </div>
            <div className="formPhone">
              <input
                className="form-control"
                id="phone"
                placeholder="Phone"
                type="text"
              />
            </div>
            <div className="formCmnt">
              <textarea
                className="form-control"
                id="comments"
                placeholder="Please describe what you need."
              ></textarea>
            </div>
            <div className="formBtn">
              <button type="#" name="submit">
                Get a free consultation
              </button>
            </div>
          </form>
        </div>
        <div className="contactAddress">
          <h1>
            Address &nbsp;
            <a href="#!"><span id="map">Google Map</span></a>
          </h1>

          <div className="contactA-content">
            <span>
              <i class="fas fa-map-marker-alt"></i>
            </span>
            <div className="c-address">
              <h4>OUR LOCATION</h4>
              Suit No. 502, Plot No. 7c/1, Main Khyaban-e-Ittehad, DHA Phase 7,
              Karachi-Pakistan
            </div>
          </div>

          <div className="contactA-content">
            <span>
              <i class="fas fa-phone-alt"></i>
            </span>
            <div className="c-phone">
              <h4>CALL US</h4>
              +92-320-2579810 +92-320-2579820
            </div>
          </div>

          <div className="contactA-content">
            <span>
              <i className="far fa-envelope"></i>
            </span>
            <div className="c-email">
              <h4>SEND US EMAIL</h4>
              customercare@masveritas.net
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Form;
