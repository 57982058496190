import React from 'react'
import './footer.scss'
import img from '../../assets/images/newLogo.png'
const Footer = ({className}) => {
    return (
      <>
        <div className="footerMain grid-row">
          <div className="footer-col1">
            <img src={img} className={"footerLogoimg " + className} />
            <p>
              Happen active county. Winding for the morning am shyness evident
              to poor. Garrets because elderly new.
            </p>
            <form>
              <input
                type="email"
                placeholder="Your Email"
                class="form-control"
                name="email"
              />
              <button type="submit">
                {" "}
                <i class="fa fa-paper-plane"></i>
              </button>
            </form>
          </div>
          <div className="footer-col21">
            <h2 class="font-weight-bold text-uppercase mb-4">Services</h2>

            <ul class="list-unstyled footermenu">
              <li>
                  <a href="/ServicesChain"><i className="fas fa-arrow-right"></i>Supply Chain Services</a>
                
              </li>
              <li>
                  <a href="/ServicesInteg"><i className="fas fa-arrow-right"></i>Asset Integrity Services</a>
              </li>
              <li>
                  <a href="/ServicesDnd"><i className="fas fa-arrow-right"></i>Destructive and Non-Destructive</a>
              </li>
              <li>
                  <a href="/ServicesCathod"><i className="fas fa-arrow-right"></i>Cathodic Protection Services</a>
              </li>
              <li>
                  <a href="/ServicesTrain"><i className="fas fa-arrow-right"></i>Training</a>
              </li>
              <li>
                  <a href="/ServicesLift"><i className="fas fa-arrow-right"></i>Lifting equipment inspections</a>
              </li>
              <li>
                  <a href="/ServicesHalal"><i className="fas fa-arrow-right"></i>Halal certifications</a>
              </li>
              <li>	
                  <a href="/ServicesRenew"><i className="fas fa-arrow-right"></i>Renewable Energy Services</a>
              </li>
            </ul>
          </div>
          <div className="footer-col3">
            <h2 class="font-weight-bold text-uppercase mb-4">Follow us</h2>

            <ul class="list-unstyled">
              <li>
               
                  <a href="#!">Facebook</a>
                
              </li>
              <li>
                
                  <a href="#!">Linkedin</a>
                
              </li>
              <li>
                
                  <a href="#!">Instagram</a>
                
              </li>
              <li>
                
                  <a href="#!">Youtube</a>
                
              </li>
              <li>
                
                  <a href="#!">Vimeo</a>
                
              </li>
            </ul>
          </div>
          <div className="footer-col4 contactFooter">
            <h2>Contact us</h2>
            
              
              <div className='footer-Con-text'>
                <span><i class="fas fa-map-marker-alt"></i></span>
                <div className="address">Suit No. 502, Plot No. 7c/1, Main Khyaban-e-Ittehad, DHA
                Phase 7, Karachi-Pakistan</div>
              </div>
            
              
              <div className='footer-Con-text'>
                 <span><i class="fas fa-phone-alt"></i></span>
                  <div className="f-phone">+92-320-2579810 +92-320-2579820</div>
                  
              </div>
              
              <div className='footer-Con-text email-f'>
              
                  <span><i className="far fa-envelope"></i></span>
                  <a href="#!">customercare@masveritas.net</a>
              </div>
            
          </div>
        </div>
        <div className='copyRight grid-row'>
            <div className='crRight'>
             <p> &copy; CopyRight 2023-2024. All rights reserved </p>
            </div>
            {/* <div className='crLeft'>
                <ul>
                    <li><a href="#!">Home</a></li>
                    <li><a href="#!">About</a></li>
                    <li><a href="#!">Contact</a></li>
                </ul>
            </div> */}
        </div>
      </>
    );
}

export default Footer;