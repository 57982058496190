import React from "react";
import "./Career.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
const Career = () => {
  return (
    <>
      <InnerBanner img={banner} title="CAREER" bradCrums="Career" />
      <div className="career content">
        <div className="career-slog grid-row">
          <div className="slog">
            <h4>SOLVING PROBLEMS, BUILDING CAREERS. YOUR FUTURE LIES HERE!</h4>
            <span className="slog-num">Slogan#01</span>
          </div>
          <div className="slog">
            <h4>DREAM BIG. JOIN MAS VERITAS!</h4>
            <span className="slog-num">Slogan#02</span>
          </div>
          <div className="slog">
            <h4>BE WHERE YOU ARE CELEBRATED. JOIN US AT MAS VERITAS.</h4>
            <span className="slog-num">Slogan#03</span>
          </div>
        </div>
        <div className="career-content">
          <h1>Careers</h1>
          <p>
            <strong>
              MAS VERITAS OFFERS YOU SUSTAINABILITY, POTENTIAL ADVANCEMENT, AND
              THE OPPORTUNITY TO MAKE AN IMPACT.
            </strong>
          </p>
          <p>
            When you join the MAS Veritas family, you join a goal to make
            Pakistan and the world a safer, better, and more connected place by
            putting your skills and abilities to work that will benefit society
            in many ways. When it comes to effort, we have no bounds. We value
            diversity at MAS Veritas and consider our staff to be our most
            valuable resource. We're looking for employees who are enthusiastic
            about their work while still being ethical, moral, and reliable. MAS
            Veritas is your next ideal job if you're ready to start on an
            adventure where you'll go above and beyond to flourish
            professionally.
          </p>
        </div>
        <div className="career-imp grid-row">
          <div className="career-imp-l">
            <h3>Sharing, Caring, and Growing together</h3>
            <p>
              MAS Veritas gives you the benefits of learning and working with a
              team of lead experts in the testing, inspection, and certification
              industry. We collaborate in an environment that values
              transparency, compassion, and understanding. We make sure that our
              members grow and prosper in an atmosphere where people respect and
              support one another. MAS Veritas gives you the benefits of
              learning and working with a team of lead.
            </p>
          </div>
          <div className="career-imp-m">
            <h3>Encouraging Diversity</h3>
            <p>
              Workplace diversity is fundamental in mounting and constructing a
              company successfully. MAS Veritas aims to construct a strong and
              smart firm with a diverse team. The corporate world is becoming
              increasingly competitive and in order to compete MAS Veritas
              believes that diversity of viewpoint is equally essential along
              with other aspects. We believe that everybody, regardless of their
              location or culture, deserves an equal chance to succeed and that
              diversity enriches the world.
            </p>
          </div>
          <div className="career-imp-l">
            <h3>Making History</h3>
            <p>
              MAS Veritas is one of the first testing, inspection, and
              certification to set its root in Karachi, the commercial hub of
              Pakistan. It's a huge shift that affects every team in our
              organisation. Join us, and you'll have the chance to accomplish
              great ideas, create the future, and collaborate with some of the
              top brains in the industry. If your goal is similar to ours and
              you're willing to develop a future with boldness and curiosity,
              join us and become a part of history.
            </p>
          </div>
        </div>
        <div className="career-job grid-row">
          <div className="career-job-l">
            <h1>JOB OPPORTUNITIES</h1>
            <p>
              All candidates are treated with respect, professionalism, and
              honesty at MAS Veritas. We regard each individual for their
              professional qualities, and we prioritise diversity while forming
              our teams. Do you wish to join MAS Veritas? View our current
              vacant positions or email us your CV. Mas Veritas and its team
              wishes you the best of luck with your application.
              <span>Send us your CVs at <a href="#!">career@masveritas.net</a></span>
            </p>
          </div>
          <div className="career-job-R">
            <h4>VACANT POSITIONS</h4>
            <div className="jobPoint">
              <span>
                <i class="fa-solid fa-circle-check"></i>
              </span>
              <div className="jobPoint-para">API engineers</div>
            </div>
            <div className="jobPoint">
              <span>
                <i class="fa-solid fa-circle-check"></i>
              </span>
              <div className="jobPoint-para">Content Marketer</div>
            </div>
            <div className="jobPoint">
              <span>
                <i class="fa-solid fa-circle-check"></i>
              </span>
              <div className="jobPoint-para">Accountant</div>
            </div>
            <div className="jobPoint">
              <span>
                <i class="fa-solid fa-circle-check"></i>
              </span>
              <div className="jobPoint-para">Lead Auditor </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Career;
