import React from "react";
import "./ServicesTrain.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-img4.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesTrain = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Training"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            MAS Veritas recognizes the importance of adequate training in
            maximizing performance, thus we provide the highest level of
            professional training to help you enhance your employee's abilities.
          </p>
          <p>
            {" "}
            Quality, Performance, Health-and-Safety and Technical Development
            Training are just a few of the subjects covered in our training
            plan. Our courses are designed to meet the needs of people of
            various skill levels to meet the expectations of any organization.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>We offer training in the following subjects</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            API Preparation Courses (API-510, API-570, API-653, API-580)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            ASNT Level-2 Training (UT, MPI, DPT, ECT, RT)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Coating Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Safe Lifting Operations
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Auditor development training
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            HSE Trainings
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Safe Lifting Operations
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Non-Intrusive Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            High Temperature Hydrogen Attack
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Advance NDT (PAUT, ToFD, Digital Radiography)
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            <p>
              Quality, Performance, Health-and-Safety and Technical Development
              Training are just a few of the subjects covered in our training
              plan. Our courses are designed to meet the needs of people of
              various skill levels to meet the expectations of any organization.
            </p>
            <h1>Training</h1>
            <p>
              With our training, your employees can strengthen their skills and
              broaden their perspective in this vast field. This will
              exponentially improve your company's organizational agility, lower
              labor costs, and take operational efficiency to the skies, all of
              which will ultimately allow your company to expand and flourish
              culturally and acquire strategic distinctiveness.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesTrain;
