import React from "react";
import "./ServicesInteg.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-img3.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesInteg = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Assets integrity"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Asset integrity assessments decrease the potential of unplanned
            asset shutdown and offer peace of mind regarding the reliability of
            the operational plant. Asset integrity management requires a
            thorough understanding of the state of crucial components.
          </p>
          <p>
            {" "}
            Acquiring thorough knowledge of the true cause of material aging in
            the equipment, such as deterioration, corrosion control, monitoring,
            or manufacturing flaws, enables the best immediate and long-term
            repair options while balancing the asset's economics, safety, and
            overall life management.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>MAS Veritas offers</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
              Visual Inspection and integrity assessment of static equipment
              i.e. Pressure Vessels, Above ground / Under-ground storage tanks,
              Plant piping and pipelines
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Risk Based Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Root Cause Failure Analysis
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            <p>
              Acquiring thorough knowledge of the true cause of material aging
              in the equipment, such as deterioration, corrosion control,
              monitoring, or manufacturing flaws, enables the best immediate and
              long-term repair options while balancing the asset's economics,
              safety, and overall life management.
            </p>
            <h1>Asset integrity services</h1>
            <p>
              This is done safely and cost-effectively to offer justification
              for decisions to run, repair, or replace. Your company's
              proprietary assets are safe with MAS Veritas' integrity services.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesInteg;
