import React from 'react'
import './Components.scss'
const TopBar = () => {
    return (
        <div className='topBar'>
            <div className='grid-row'>
                <div className='col-6'>
                    <div className='topAddress'>
                        <span className='iconTopBar'>
                            <i className="fa-solid fa-location-dot"></i>
                        </span>
                        <span>Main Khyaban-e-Ittehad,DHA</span>
                        <span className='saprater'></span>
                        <span className='iconTopBar'>
                            <i className="fa-solid fa-envelope"></i>
                        </span>
                        <span>info@masveritas.net</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar