import React from 'react'
import AppImage from '../Imgaes/Images'
import img from '../../assets/images/banner-image.png';
import Button from '../Button/Button'
import './appBanner.scss';
const AppBanner = () => {
    return (
        <div className='app-banner'>
            <div className='grid-row'>
                <div className='col-5'>
                    <div className='left-banner-text'>
                        <h2 className='animate__animated animate__backInLeft'>Certification Consulting.</h2>
                        <div className='normalText animate__animated animate__backInLeft'>
                            International Halal Certification, Halal Scheme is a Product as well as Process certification, confirming traceability of complete supply chain, from Farm to Fork.
                        </div>
                        <div className='buttonContainer animate__animated animate__backInLeft'>
                            <Button
                                className="bannerButton"
                                buttonText="Discover more"
                                theme="outline"
                            />
                            

{/* <div className="svg-wrapper">
  <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg">
    <rect className="shape" height="60" width="320" />
    <div className="text">ZACH SAUCIER</div>
  </svg>
  </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-5'>
                    <div className='right-banner-image animate__animated animate__backInRight'>
                        <AppImage img={img} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppBanner;