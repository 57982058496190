import React from "react";
import './innerBanner.scss'

const InnerBanner = ({img, className, id, title, subHeading, bradCrums}) => {
    
    return (
        <div 
        className={"InnerBanner " + className} 
        style={{ 
            backgroundImage: `url(${img})`,
            width : "100%", 
            height : "500px", 
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed",
            }}>
            <div className="content">
                <div className="banner-data">
                <div className="innerText">
                    <h2>{title}</h2>
                    <h4>{subHeading}</h4>
                </div>
                <div className="bradcrumbs">
                    <ul>
                        <li>
                            <a href="/"><i className="fas fa-home"></i>HOME</a>
                        </li>
                        <li>
                            <a><i className="fas fa-o"></i>{bradCrums}</a>
                        </li>
                    </ul>
                </div>
            </div>
           </div>
        </div>
    )
}

export default InnerBanner;