import React from "react";
import "./ServicesCathod.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-bot2.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesCathod = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Cathodic Protection"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Cathodic protection is a dynamic preventive technique that can stop
            or slow the pace of corrosion under severe soil or electrolyte
            conditions. It is usually used in combination with coating systems.
            Electrochemical cathodic protection is used to preserve buried or
            submerged metallic infrastructures such as pipelines, storage tanks,
            and offshore constructions against corrosion.
          </p>
          <p>
            {" "}
            Cathodic protection is a dynamic preventive technique that can stop
            or slow the pace of corrosion under severe soil or electrolyte
            conditions. It is usually used in combination with coating systems.
            Electrochemical cathodic protection is used to preserve buried or
            submerged metallic infrastructures such as pipelines, storage tanks,
            and offshore constructions against corrosion.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>Services offered by MAS Veritas in the field of CP are</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Design, Procurement, Installation and commissioning of a CP system
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Supply of CP and corrosion testing materials
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Technical Audits
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Upgradation of CP system
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            External Corrosion Direct Assessment
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            <h1>Cathodic protection services</h1>
            <p>
              MAS Veritas can design, provide, install, and maintain any form of
              cathodic protection system, owing to its highly skilled engineers
              and technicians certified at various levels by the National
              Association of International Corrosion Engineers.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesCathod;
