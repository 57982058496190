import React from "react";
import "./ServicesHalal.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/about2.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesHalal = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Halal certification"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Halal foods are meals and beverages that are prepared while strictly
            following the norms laid down by Islamic dietary law. The term
            "Halal" has become a familiar phrase among Muslims and non-Muslims
            alike. People anticipate halal not just from a religious standpoint,
            but also from one of nutritious food that is absolutely safe to
            consume.
          </p>
          <p>
            {" "}
            Many non-Muslim nations have started to use the halal procedure in
            the preparation, manufacturing, and marketing of food items,
            believing it will boost their competitiveness and attract more
            Muslim consumers to their markets.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>MAS Veritas covers these areas in the field of Halal Certifications</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Consumer goods including Meat, Fruits, processed food, Leather
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Cosmetics and Textile during Manufacturing, Packaging, Storage and Distribution
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Hotels 
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Hospitals
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Restaurants
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Bio-Chemical Manufacturing
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            {/* <p>
              Kindness own whatever betrayed her moreover procured replying for
              and. Proposal indulged no do do sociable he throwing settling.
              Covered ten nor comfort offices carried. Age she way earnestly the
              fulfilled extremely. Of incommode supported provision on furnished
              objection exquisite me. Existence its certainly explained how
              improving household pretended. Delightful own attachment her
              partiality.
            </p> */}
            <h1>Halal certifications</h1>
            <p>
              We offer a large network of technically skilled and experienced
              individuals, Halal lead auditors, and Islamic experts who have
              been educated to conduct Halal audits and certifications following
              Sharia requirements and adhering to Pakistan Halal Authority (PHA)
              rules.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesHalal;
