import React from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const SliderComponent = ({slidesToShow, items,dots, arrow, id}) => {
    const [slide, sliders] = React.useState(items)

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className + " nextKey"}
            style={{ ...style}}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className + " preKey"}
            style={{ ...style}}
            onClick={onClick}
          />
        );
      }
    
    
    const settings = {
        dots: dots,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        slidesToShow: slidesToShow,
        swipeToSlide: true,
        centerPadding: "150px",
        cssEase: "linear",
        nextArrow: arrow && <SampleNextArrow />,
        prevArrow: arrow && <SamplePrevArrow />
       
    };

    return (
        
        <div className='mainHeading animate__animated animate__slideInUp  service-slider'>
        <Slider {...settings}>
            {slide && slide.map((items, key) => {
                return <div id={id+key} className='logoSlider' key={items}>
                        <img src={items.src} />
                    </div>
            })}
             
        </Slider>
      
        </div>
        
    )
}

export default SliderComponent;