import React from 'react'
import './MeetOurTeam.scss'
import img1 from '../../assets/images/samiIqbal.png'
import img2 from '../../assets/images/usama.png'
import img3 from '../../assets/images/shahzeb.png'
import img4 from '../../assets/images/sohail.png'
import img5 from '../../assets/images/yasir.png'
import img6 from '../../assets/images/haroon.png'
const MeetOurTeam = () => {
    return (
        <>
        <div className='meetourteam'>
            <div className='memberGrid'>
                <div className='memberRow'>
                    {/** col */}
                    <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img1} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>SAMI IQBAL</h2>
                                    <h4>(CEO & Co-founder)</h4>
                                </div>
                                <div className='memberDetail'>
                                    Born and raised in the heart of Pakistan - Karachi, Sami Iqbal's success stems from a variety of
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                     {/** col */}
                     <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img2} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>USAMA SIDDIQUI</h2>
                                    <h4>(Director & Co-founder)</h4>
                                </div>
                                <div className='memberDetail'>
                                Usama Siddiqui has over four years of customer service, sales and operations, inventory
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                     {/** col */}
                     <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img3} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>SHAHZEB MEHBOOB</h2>
                                    <h4>(Country manager UAE)</h4>
                                </div>
                                <div className='memberDetail'>
                                Shahzeb Mehboob is an expert in software engineering and project management. He has had
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                </div>

                <div className='memberRow'>
                    {/** col */}
                    <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img4} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>SOHAIL ASHRAF</h2>
                                    <h4>(Country Manager Oman)</h4>
                                </div>
                                <div className='memberDetail'>
                                Sohail Ashraf brings to the company over 30 years of senior financial expertise, with a focus on 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                     {/** col */}
                     <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img5} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>YASIR AHMED QURESHI </h2>
                                    <h4>(Country Manager Malaysia)</h4>
                                </div>
                                <div className='memberDetail'>
                                Yasir Ahmed Qureshi is an individual who is highly driven and proactive, has the vision and desire to 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                     {/** col */}
                     <div className='memberCol'>
                        <div className='items'>
                            <div className='item-inner'>
                                <div className='memeberImg'>
                                    <img src={img6} />
                                </div>
                                <div className='memeberDesignation'>
                                    <h2>HAROON AHMED JUNAIDI </h2>
                                    <h4>(Country Manager UK)</h4>
                                </div>
                                <div className='memberDetail'>
                                Haroon Junaidi brings extensive senior management experience of over two decades in
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** col end */}
                </div>
                
            </div>
        </div>
        </>
    )
}

export default MeetOurTeam;