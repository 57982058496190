import React from "react"
import AppImage from '../Imgaes/Images'
import Logo from '../../assets/images/newLogo.png'
import AppNavigation from './Navigarion'
import HeaderCall from './headerCall'
const Header = () => {
    return (
        <div className="AppHeaderNavigation">
            <div className="grid-row">
                <div className="col">
                    <div className="logo">
                        <AppImage img={Logo} className="AppLogo" title="Massveritas" /> 
                    </div>
                </div>
                <div className="col-9">
                    <AppNavigation />
                </div>
                <div className="col">
                    <HeaderCall />
                </div>
            </div>
        </div>
    )
}

export default Header;