import React from 'react'
import AboutUs from '../../Components/Aboutus/Aboutus'
import SimpleHeading from '../../Components/Heading/LeftHeading'
import InnerBanner from '../../Components/InnerBanner/InnnerBanner'
import banner from '../../assets/images/about3.jpg'
import whoWe from '../../assets/images/whoweare.jpg'
import Button from '../../Components/Button/Button'
import img1 from '../../assets/images/72.jpg'

import './AboutUsPage.scss'
const Aboutus = () => {
    return (
        <>
        <InnerBanner 
            img={banner}
            title="ABOUTS US"
            bradCrums="Abouts us"
        />        
        <div className='content'>
            <div className='aboutus' data-aos="fade-up">
                <AboutUs />
            </div>

            <div className='aboutus' data-aos="fade-up">
                <div className='whoweare'>
                    <div className='whoImage'>
                        <img src={whoWe} />
                    </div>
                    <div className='whoText'>
                        <SimpleHeading 
                            largeText="who we are?"
                            textAling="left"
                        />
                        <div className='weText'>
                        MAS Veritas is a team of lead experts of multi-talented engineers who has a diversified experience in world’s leading organizations. MAS Veritas has state-of-the-art technology to provide comprehensive solutions for your company. Though the name MAS Veritas may be unfamiliar to you, it is worth noting that the company's founders have extensive experience in the industry.
                        </div>
                    </div>
                </div>
            </div>

            <div className='vision-mission-about' data-aos="fade-right">
                    <div className='vm-content'>
                        
                        <div className='vm-text'>
                            <div className='ourMission'>
                                <h2>Mission</h2>
                                <h3>Our Mission</h3>
                                <div className='inner-text-vm'>
                                Our team at MAS Veritas is dedicated to providing the finest solutions for your business while also lifting the bar for your goods to satisfy worldwide market requirements. Trust is one of the golden attributes that holds immense importance in any market - national or international. Consequently, we are committed to fostering trust in Halal goods, advancements, and facilities of high quality products. As a Pakistan-based international corporation, MAS Veritas is committed to strengthening Pakistan's exports by establishing its offices across the world. 
                                </div>
                               
                                
                            </div>

                            <div className='ourVision'>
                                <h2>Vision</h2>
                                <h3>Our Vision</h3>
                                <div className='inner-text-vm'>
                                MAS Veritas was founded with the goal of increasing awareness and improving the working standards of companies in Pakistan. We want to give back to our country by guaranteeing that its goods and services are of high quality and follow halal procedures.
                                </div>
                                
                            </div>
                        </div>
                        <div className='vm-img'>
                            <img src={img1} />
                        </div>
                    </div>
                </div>
                
        </div>
        </>
    )
}

export default Aboutus;