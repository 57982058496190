import React from 'react'
import Widgets from '../Components/Widgets/widgets'
import SCIcon from '../assets/images/SC-icon.png'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DynamicSlides from './test'
const items = [
    {
        id : "1", 
        title : "Supply Chain Services", 
        src: SCIcon, 
        button : "Discover more", 
        description : "Professional, unbiased auditing is among the most effective methods to provide value"
    },
    {
        id : "2", 
        title : "Asset Integrity Services", 
        src: SCIcon, 
        button : "Discover more", 
        description : "Asset integrity assessments decrease the potential of unplanned asset shutdown and"
    },
    {
        id : "3", 
        title : "Destructive and Non-Destructive Services",
        src: SCIcon, 
        button : "Discover more", 
        description : "Destructive testing damages or modifies the"
    },
    {
        id : "4", 
        title : "Cathodic Protection Services", 
        src: SCIcon, 
        button : "Discover more", 
        description : "Cathodic protection is a dynamic preventive technique that can stop or slow the pace"
    },
    {
        id : "5", 
        title : "Training", 
        src: SCIcon, 
        button : "Discover more", 
        description : "MAS Veritas recognizes the importance of adequate training in maximizing"
    },
    {
        id : "6", 
        title : "Lifting equipment inspections", 
        src: SCIcon, 
        button : "Discover more", 
        description : "Lifting activities that are safe and successful rely heavily on the continuing safety"
    },
    {
        id : "7", 
        title : "Halal certifications", 
        src: SCIcon, 
        button : "Discover more", 
        description : "Halal foods are meals and beverages that are prepared while strictly following the"
    },
    {
        id : "8", 
        title : "Renewable Energy Services", 
        src: SCIcon, 
        button : "Discover more", 
        description : "MAS Veritas strives tirelessly to be at the forefront of renewable technologies as the "
    }
]

const dx = [1,2,3,4,5,6];

const ServiceSlider = () => {
    const [slide, sliders] = React.useState(items)
    const [slidex, slidersx] = React.useState(dx)

    React.useEffect(() => {
        slidersx(dx);
    },[])
    const gotoDetail = (id, title) => {
        console.log(id, )
    }
    
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "150px",
    };

    // const services =  slide.map((items, index) => {
    //     return <div className='col-6' key={items.id}>
    //         <Widgets 
    //         img={items.src}
    //         WidTitle={items.title}
    //         buttonName={items.button}
    //         onClick={gotoDetail(items.id, items.title)}
    //         number={items.id}
    //         Description={items.description}
    //     /></div>
    // })
    
    return (
        
        <div className='mainHeading animate__animated animate__slideInUp  service-slider'>
        <Slider {...settings}>
            {slide && slide.map((items) => {
                return <div className='' key={items}>
                    <Widgets 
                    img={items.src}
                    WidTitle={items.title}
                    buttonName={items.button}
                    onClick={gotoDetail(items.id, items.title)}
                    number={"0" + items.id}
                    Description={items.description}
                /></div>
            })}
             
        </Slider>
      
        </div>
        
    )
}

export default ServiceSlider;