import React from 'react'


const HeaderCall = () => {
    return (
        <div className='call-right'>
            <div className='phoneImage'></div>
            <div className='phoneNumer'>
                <span>Call us today!</span>
                <h4>+92-300-9281819</h4>
            </div>
        </div>
    )
}

export default HeaderCall;