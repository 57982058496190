import React from "react";
import './Team.scss'
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import MeetOurTeam from "../../Components/MeetOurTeam/MeetOurTeam";
const Team = () => {
  return (
    <>
      <InnerBanner img={banner} title="Our Team" bradCrums="Team" />
      <div className="content">
        <div className='teamPara'>
        <p>
          MAS Veritas' employees share the same mission and values as our
          community. We are motivated by the belief that the finest work comes
          from hard work, creativity, and a good sense of humor.
        </p>
        </div>
        <MeetOurTeam />
      </div>
    </>
  );
};
export default Team;
