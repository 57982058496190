import React from "react";
import "./ServicesLift.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-img5.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesLift = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Lifting equipment inspections"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Lifting activities that are safe and successful rely heavily on the
            continuing safety of the lifting equipment and accessories that are
            employed. Failures in this type of equipment can cause fatal
            injuries. To appropriately manage these hazards, health and welfare
            law mandates several specific requirements on individuals who
            provide, control and use lifting equipment.
          </p>
          <p>
            {" "}
            Lifting attachments must also be tested every six months since if
            they are to malfunction, the results could be catastrophic. If
            necessary, other forms of conventional lifting equipment can be
            tested once a year.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>Statutory and voluntary crane inspection for</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Material-handling devices: including cranes, derricks, fork-lifts, truck-mounted cranes.
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Lifting Accessories like, shackles, hooks, elevating platforms, and telehandlers 
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Personnel handling devices: such as elevators, escalators, ski lifts, cable cars and mobile elevating work platforms (MEWPs). 
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Personal Protective Equipment
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            {/* <p>
              Kindness own whatever betrayed her moreover procured replying for
              and. Proposal indulged no do do sociable he throwing settling.
              Covered ten nor comfort offices carried. Age she way earnestly the
              fulfilled extremely. Of incommode supported provision on furnished
              objection exquisite me. Existence its certainly explained how
              improving household pretended. Delightful own attachment her
              partiality.
            </p> */}
            <h1>Lifting equipment inspections</h1>
            <p>
              Lifting equipment that carries personnel, on the other hand,
              should be examined every six months as this poses the greatest
              danger of injury. Lifting attachments must also be tested every
              six months since if they are to malfunction, the results could be
              catastrophic. If necessary, other forms of conventional lifting
              equipment can be tested once a year. We offer a professional
              lifting gear inspection service at MAS Veritas to assist you to
              keep your equipment safe and functional. MAS Veritas can offer you
              the expertise needed to conduct professional.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesLift;
