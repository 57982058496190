import React from "react";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import Form from "../../Components/Form/Form";
const Contact = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Contact Us"
        bradCrums="Contact us"
      />
      <Form />
    </>
  );
};
export default Contact;
