import React from "react";
import "./ServicesRenew.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/72.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesRenew = () => {
  return (
    <>
      <InnerBanner img={banner} title="Services\Renewable Energy" bradCrums="Services" />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
          MAS Veritas strives tirelessly to be at the forefront of renewable technologies as the desire for safe and renewable energy tends to increase massively. We offer state-of-the-art technology and our expert team to monitor and maximize efficiency and ensure the growth of your renewable energy projects. 
          </p>
          {/* <p>
            {" "}
            Kindness own whatever betrayed her moreover procured replying for
            and. Proposal indulged no do do sociable he throwing settling.
            Covered ten nor comfort offices carried. Age she way earnestly the
            fulfilled extremely. Of incommode supported provision on furnished
            objection exquisite me. Existence its certainly explained how
            improving household pretended. Delightful own attachment her
            partiality.
          </p> */}
        </div>
      </div>
      <div className="content">
        <h2>MAS Veritas offers Solar Power Installation Consultancy services which includes</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Design Review
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Product Review
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Solar Power Site monitoring Services
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Feasibility and Simulation Studies
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            {/* <p>
              Kindness own whatever betrayed her moreover procured replying for
              and. Proposal indulged no do do sociable he throwing settling.
              Covered ten nor comfort offices carried. Age she way earnestly the
              fulfilled extremely. Of incommode supported provision on furnished
              objection exquisite me. Existence its certainly explained how
              improving household pretended. Delightful own attachment her
              partiality.
            </p> */}
            <h1>Renewable energy</h1>
            <p>
            We assist you in monitoring and mitigating the effects of energy usage to make your company more sustainable. With our robust Sustainability solutions, you can improve energy efficiency, create or consume renewable energy, and cut carbon gas production while evaluating clean energy practices.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesRenew;
