import React from 'react'
import TopBar from './topBar'
import Header from './Header'
const AppHeader = () => {
    return (
        <div className='app-nv-sticky'>
            <TopBar />
            <Header />
        </div>
    )
}

export default AppHeader;