import React from 'react'
import './Button.scss';

const Button = ({onClick, className, buttonText, theme, icon}) => {
    const [buttonTheme, setButtonTheme] = React.useState()
    React.useEffect(() => {
        

        if(theme == "outline") {
            setButtonTheme("outline")
        } else {
            setButtonTheme("fill")
        }
    }, [theme])

    
    return (
        <>
            <button onClick={onClick} className={"appButton " + buttonTheme + " "  + className }>
                {buttonText} {icon}
            </button>
        </>
    )
}

export default Button;