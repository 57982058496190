import React from "react";
import "./ServicesChain.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-img.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesChain = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Supply chain"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Professional, unbiased auditing is among the most effective methods
            to provide value to an item or service. There might be flaws in your
            supply chain that you're currently aware of, as well as ones that
            have yet to be identified. These might cost you a fortune, effort,
            or both, and if you don't demonstrate that you're abiding by the
            rules, you risk damaging your credibility and perhaps facing
            consequences and penalties.
          </p>
          <p>
            {" "}
            MAS Veritas provides a variety of supply chain assurance services
            that may be tailored to meet the needs of individual customers or
            corporate programs. They're adaptable to your demands, no matter
            what industry you're in. To assist you to illustrate your dedication
            to greener manufacturing methods, we provide social audits that
            encompass sustainability and ethical trade.
          </p>
        </div>
      </div>
      <div className="content">
        <h2>Our services include</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i className="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
             Vendor Assessment
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i className="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
             Mill Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i className="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Vendor Shop Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Pre / Post shipment Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Loading / Un-Loading Supervision
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Commissioning
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Expediting Services
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            QA//QC Site Inspection
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Project Management Services
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Construction Services
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            <p>
              MAS Veritas provides a variety of supply chain assurance services
              that may be tailored to meet the needs of individual customers or
              corporate programs. They're adaptable to your demands, no matter
              what industry you're in. To assist you to illustrate your
              dedication to greener manufacturing methods, we provide social
              audits that encompass sustainability and ethical trade.
            </p>
            <h1>Supply chain services</h1>
            <p>
              We additionally facilitate your employees' and stakeholders'
              wellbeing with health and safety audits. With our expertise and
              guidance, you'll work quickly and simply towards compliance with
              local, national and international laws – reducing errors,
              increasing consistency, and promoting positive values inside your
              company.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesChain;
