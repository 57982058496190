import React from 'react'
import AppBanner from '../Components/banner/banner'
import MainHeading from '../Components/Heading/HeadingMain'
import ServiceSlider from './Services-slider'
import Button from '../Components/Button/Button'
import img1 from '../assets/images/72.jpg'
import MeetOurTeam from '../Components/MeetOurTeam/MeetOurTeam'
import AboutUs from '../Components/Aboutus/Aboutus'
import ServicesChain from './ServicesChain/ServicesChain'
import ServicesInteg from './ServicesInteg/ServicesInteg'
import ServicesDnd from './ServicesDnd/ServicesDnd'
import ServicesCathod from './ServicesCathod/ServicesCathod'
import ServicesTrain from './ServicesTrain/ServicesTrain'
import ServicesLift from './ServicesLift/SerivesLift'
import ServicesHalal from './ServicesHalal/ServicesHalal'
import ServicesRenew from './ServicesRenew/ServicesRenew'
import Team from './Team/Team'
import Form from '../Components/Form/Form'
import SliderComponent from '../Components/Slider/Slider'

import './HomePage.scss'
import logo1 from '../assets/images/9001.jpg'
import logo2 from '../assets/images/14001.jpg'
import logo3 from '../assets/images/450001.jpg'
import logo4 from '../assets/images/USA.jpg'
import logo5 from '../assets/images/9001.jpg'
import logo6 from '../assets/images/14001.jpg'
import logo7 from '../assets/images/450001.jpg'
import logo8 from '../assets/images/USA.jpg'


const HomePage = () => {

    const logoList = [
        {src: logo1},
        {src: logo2},
        {src: logo3},
        {src: logo4},
        {src: logo5},
        {src: logo6},
        {src: logo7},
        {src: logo8},
    ]
    return (
        <div className='homePage'>
            <AppBanner />
            <div className='content'>
                
                <div data-aos="fade-up" className='serviceHome'>
                <MainHeading 
                    smallText="WHAT WE DO"
                    largeText="Our Services"
                    textAling="center"
                />
                <ServiceSlider />
                </div>
                
                <div className='vision-mission' data-aos="fade-right">
                    <div className='vm-content'>
                        <div className='vm-img'>
                            <img src={img1} />
                        </div>
                        <div className='vm-text'>
                            <div className='ourMission'>
                                <h2>Mission</h2>
                                <h3>Our Mission</h3>
                                <div className='inner-text-vm'>
                                Our team at MAS Veritas is dedicated to providing the finest solutions for your business while also lifting the bar for your goods to satisfy worldwide market requirements.
                                </div>
                                <Button
                                    className="bannerButton"
                                    buttonText="Know more"
                                    theme="outline"
                                    icon={<i className='fas fa-arrow-alt-circle-right'></i>}
                                />
                                
                            </div>

                            <div className='ourVision'>
                                <h2>Vision</h2>
                                <h3>Our Vision</h3>
                                <div className='inner-text-vm'>
                                MAS Veritas was founded with the goal of increasing awareness and improving the working standards of companies in Pakistan. We want to give back to our country by guaranteeing
                                </div>
                                <Button
                                    className="bannerButton"
                                    buttonText="Know more"
                                    theme="outline"
                                    icon={<i className='fas fa-arrow-alt-circle-right'></i>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='team-heading' data-aos="fade-left">
                <MainHeading 
                    smallText="VALUEABLE MEMEBERS"
                    largeText="MEET OUR TEAM"
                    textAling="center"
                />
                
                <MeetOurTeam />
                </div> */}
                
               
               

                <div className="ourClients" data-aos="zoom-in">
                    <SliderComponent id="clientLogo" items={logoList} slidesToShow={6} dots={false} arrow={true}/>
                </div>
                <div>
                <Form className='Form' data-aos="fade-left"/>
                </div>
            </div>
        </div>
    )
}

export default HomePage;