import React from 'react'
import './mainHeading.scss'

const MainHeading = ({smallText, largeText, className, textAling}) => {
    return (
        <>
            <div className={'mainHeading animate__animated animate__slideInUp ' + className} style={{textAlign : textAling}}>
                <div className='topSmall'>
                    {smallText}
                </div>
                <div className='largeText'>
                    {largeText}
                </div>
                <div className='bottomLine'></div>
            </div>
        </>
    )
}

export default  MainHeading;