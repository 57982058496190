import React from "react";
import "./ServicesDnd.scss";
import InnerBanner from "../../Components/InnerBanner/InnnerBanner";
import banner from "../../assets/images/about3.jpg";
import servImg1 from "../../assets/images/s-bot1.jpg";
import servImg2 from "../../assets/images/s-img2.jpg";
const ServicesDnd = () => {
  return (
    <>
      <InnerBanner
        img={banner}
        title="Services\Destructive and Non-Destructive"
        bradCrums="Services"
      />
      <div className="content grid-row">
        <div className="servImg">
          <img src={servImg1} className="servImg" />
        </div>
        <div className="servDscrpt">
          <h1>Content Overview</h1>
          <p>
            Destructive testing damages or modifies the part in a certain way,
            rendering it unfit for use even if it passes the inspection.
            <p><strong>In the
            area of Destructive Testing MAS Veritas provides</strong></p>
          </p>
          <ul>
              <li>Tensile testing</li>
              <li>Three-point bend testing</li>
              <li>Macro sectioning</li>
              <li>Chemical Analysis</li>
              <li>Hardness Testing</li>
              <li>Metallography</li>
              <li>Impact Testing </li>
            </ul>
        </div>
      </div>
      <div className="content">
        <h2>In the area of Non-Destructive Testing MAS Veritas provides</h2>
        <div className="whyChoose">
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Ultrasonic Testing (UT)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Magnetic Particle Inspection (MPI)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Dye Penetrant Testing (DPT)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Visual Inspection (VT)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Radiographic Testing (RT)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Replica Resting / Field Metallography
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Positive Material Identification (PMI)
            </div>
          </div>
          <div className="wc-content">
            <span>
              <i class="fa-solid fa-circle-check"></i>
            </span>
            <div className="wc-content-para">
            Hardness Testing
            </div>
          </div>
        </div>
        <div className="serv-content-2 grid-row">
          <div className="serv-content-para">
            <p>
              Non-destructive testing (NDT) is a wide term that refers to a set
              of procedures for evaluating the qualities of a material,
              component, product, weld, or system without compromising the
              integrity of the unit being inspected or probed.
            </p>
            <h1>Destructive and non-destructive services</h1>
            <p>
              MAS Veritas can guarantee its clients that their assets are
              compliant with all applicable requirements, thanks to our
              destructive and innovative NDT solutions. MAS Veritas' technical
              inspection decreases the risk to the assets in concern while also
              assisting in the maintenance of production.
            </p>
          </div>
          <div className="serv-content-img">
            <img src={servImg2} className="serv-content-img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesDnd;
