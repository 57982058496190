import React from 'react'
import './widget.scss'
const Widgets = ({img, name, WidTitle, Description, number, onClick, buttonName}) => {
    return(
      
            <div className='app-widgets'>
                <div className='wid-content'>
                    <div className='wid-img'>
                        <img src={img} name={name} />
                    </div>
                    <div className='wid-text-data'>
                        <h2>{WidTitle}</h2>
                        <div className='wid-short-text'>
                            {Description}
                        </div>
                        <div className='number-button'>
                            <div className='wid-number'>
                                {number}
                            </div>
                            <div className='wid-button-div'>
                                <button onClick={onClick} className="simpleButton">
                                    <span></span>{buttonName} 
                                    <i className="fa-solid fa-circle-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        
    )
}

export default Widgets;