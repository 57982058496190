import React from 'react'


const AppNavigation = () => {
    return (
     
        <div className='app-navigation'>
           
            <ul>
                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="/Aboutus">About Us</a>
                </li>
                <li className='dropdown'>
                    <a href="#">Services</a>
                    <div className="dropdown-content animate__animated animate__fadeIn">
                    <ul class="headerSubMenu">
                        <li>
                            <a href="/ServicesChain"><i className="fas fa-arrow-right"></i>Supply Chain Services</a>
                        </li>
                        <li>
                            <a href="/ServicesInteg"><i className="fas fa-arrow-right"></i>Asset Integrity Services</a>
                        </li>
                        <li>
                            <a href="/ServicesDnd"><i className="fas fa-arrow-right"></i>Destructive and Non-Destructive Services</a>
                        </li>
                        <li>
                            <a href="/ServicesCathod"><i className="fas fa-arrow-right"></i>Cathodic Protection Services</a>
                        </li>
                        <li>
                            <a href="/ServicesTrain"><i className="fas fa-arrow-right"></i>Training</a>
                        </li>
                        <li>
                            <a href="/ServicesLift"><i className="fas fa-arrow-right"></i>Lifting equipment inspections</a>
                        </li>
                        <li>
                            <a href="/ServicesHalal"><i className="fas fa-arrow-right"></i>Halal certifications</a>
                        </li>
                        <li>	
                            <a href="/ServicesRenew"><i className="fas fa-arrow-right"></i>Renewable Energy Services</a>
                        </li>
                        </ul>
                    </div>
                </li>
                {/* <li>
                    <a href="/Team">Team</a>
                </li> */}
                
                <li>
                    <a href="#">Clients</a>
                </li>
                <li>
                    <a href="#">Accreditation & Certifications</a>
                </li>
                <li>
                    <a href="/Career">Career</a>
                </li>
                <li>
                    <a href="/Contact">Contact Us</a>
                </li>
                        {/* <li>
                        <a href="#">Home</a>
                        </li>
                        <li>
                            <a href="#">About Us</a>
                        </li>
                        <li>
                            <a href="#">Do I need a Will?</a>
                        </li>
                        <li>
                            <a href="#">How It Works</a>
                        </li>
                        
                        <li>
                            <a href="#">Our Services</a>
                        </li>
                        <li>
                            <a href="#">Blog</a>
                        </li>
                        <li>
                            <a href="#">Contact Us</a>
                        </li> */}
            </ul>
        </div>
    )
}

export default AppNavigation;