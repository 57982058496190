import './assets/allStyle.scss'
import './App.scss'
import 'animate.css';
import AppHeader from './Components/header/index'
import Footer from './Components/Footer/Footer'
import HomePage from './pages/Home'
import Aboutus from './pages/Aboutus/Aboutus'
import ServicesChain from './pages/ServicesChain/ServicesChain';
import ServicesInteg from './pages/ServicesInteg/ServicesInteg';
import ServicesDnd from './pages/ServicesDnd/ServicesDnd';
import ServicesCathod from './pages/ServicesCathod/ServicesCathod';
import ServicesTrain from './pages/ServicesTrain/ServicesTrain';
import ServicesLift from './pages/ServicesLift/SerivesLift';
import ServicesHalal from './pages/ServicesHalal/ServicesHalal';
import ServicesRenew from './pages/ServicesRenew/ServicesRenew';
import Team from './pages/Team/Team';
import Contact from './pages/Contact/Contact'
import Career from './pages/Career/Career'
import React from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'
import './assets/responsive.scss'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";



function App() {

  React.useEffect(() => {
    const scrollCounter = document.querySelector('.js-scroll-counter');

    window.addEventListener('scroll', function() {
      scrollCounter.innerHTML = window.pageYOffset;
    });
  },[])

  // AOS.init()

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

  /*
Easing functions:

linear
ease
ease-in
ease-out
ease-in-out
ease-in-back
ease-out-back
ease-in-out-back
ease-in-sine
ease-out-sine
ease-in-out-sine
ease-in-quad
ease-out-quad
ease-in-out-quad
ease-in-cubic
ease-out-cubic
ease-in-out-cubic
ease-in-quart
ease-out-quart
ease-in-out-quart
*/

  return (
    <div className="App">
    <AppHeader />
    <BrowserRouter>
     <Routes>
 
          <Route exact={true} path="/" element={<HomePage />} />
          <Route exact={true} path="/Aboutus" element={<Aboutus />} />
          <Route exact={true} path="/ServicesChain" element={<ServicesChain />} />
          <Route exact={true} path="/ServicesInteg" element={<ServicesInteg />} />
          <Route exact={true} path="/ServicesDnd" element={<ServicesDnd />} />
          <Route exact={true} path="/ServicesCathod" element={<ServicesCathod />} />
          <Route exact={true} path="/ServicesTrain" element={<ServicesTrain />} />
          <Route exact={true} path="/ServicesLift" element={<ServicesLift />} />
          <Route exact={true} path="/ServicesHalal" element={<ServicesHalal />} />
          <Route exact={true} path="/ServicesRenew" element={<ServicesRenew />} />
          <Route exact={true} path="/Team" element={<Team />} />
          <Route exact={true} path="/Contact" element={<Contact />} />
          <Route exact={true} path="/Career" element={<Career />} />
          {/* <Route exact={true} path="/justwillsweb/HowItWorks" component={Howitworks} />
          <Route exact={true} path="/justwillsweb/DoINeedWills" component={DoIneedWills} />
          <Route exact={true} path="/justwillsweb/Services" component={Services} />
          <Route exact={true} path="/justwillsweb/Contactus" component={ContactUs} />
          <Route exact={true} path="/justwillsweb/ShariaWills" component={ShariaWills} />
          <Route exact={true} path="/justwillsweb/RealEstateOwners" component={RealEstateOwners} /> */}
        
      </Routes>
      </BrowserRouter>
    <Footer/>
    </div>
  );
}

export default App;
